<!-- ########################## <Template> ########################## -->
<template lang="">
    <div >
        <Header></Header>
        <div class="pageTitle" > 
            <font-awesome-icon icon="caret-right" /> 
            Sources externes
        </div>
        <br/>
        <div class="container">

            <div v-for="(linkExterne, i) in linksData" :key="linkExterne.linkName" :index="i" class="note notecard">
                <p><a :href="linkExterne.linkUrl" target="_blank" style="text-decoration: none;">{{linkExterne.linkName}}</a></p>
            </div>

        

            <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
        </div>
    </div>
</template>
<!-- ########################## <Script> ########################## -->
<script>
import Header from "./Header.vue";

// import CRUDService from "../1-common-components/crud.service";
import {_} from 'vue-underscore';


export default {
    name: "ClientSettings",
    //--------------------------- Components --------------------------
    components: {
        Header, 
      
    },
    //------------------------------ Data -----------------------------
    data() {
        return {
            errorMessage : "",
            linksData: [
                {linkName:'www.Inforisk.ma', linkUrl:'http://www.Inforisk.ma'},
                {linkName:'www.eulerhermes.com', linkUrl:'https://www.eulerhermes.com/fr_MA.html'},
                {linkName:'www.coface.ma', linkUrl:'http://www.coface.ma'},
                {linkName:'www.checkinfo.ma', linkUrl:'http://www.checkinfo.ma'},
                {linkName:'www.africaverify.com', linkUrl:'http://www.africaverify.com'},
            ]
        }
    }, //end data()
    computed: {    
    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.log("----------- mounted() => ClientLinks");
    }, //end mounted
    //------------------------------- Created --------------------------
    created() {
        this.log("----------- created()   => ClientLinks");
    },
    //-------------------------------- Methods --------------------------
    methods: {
        
    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style scoped>
  
  .notecard.note {
    background-color: #ddeaff;
    font-size: 20px;
    text-decoration: none;
}
.notecard {
    border-left: 6px solid #2196f3;
    margin: 0 0 24px;
    padding: 12px;
}

</style>